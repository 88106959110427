import axios from "@/plugins/axios";
import BaseService from "./baseService";

class PasswordService {
  static async changePassword(data = {}, notify) {
    try {
      if (!data || !data.id || !data.username || !data.newPass) {
        notify("warning", "Informe usuário/senha!", 5000);
        return;
      }

      if (data.newPass !== data.confirmPass) {
        notify("warning", "Informe corretamente a nova senha", 5000);
        return;
      }      

      await axios.post('/users/password-change', {
        id: data.id,
        username: data.username,
        new_password: data.newPass
      })
      .then(() => {
        notify('success', 'Senha alterada com sucesso!', 5000);
        return true;
      });
    } catch (error) {
      notify("warning",
        BaseService.handleError(error, `Falha ao alterar senha, entre em contato com o suporte`), 5000);
      return false;
    }
  }

}

export default PasswordService;
