import router from '@/router';
import { getToken, removeToken } from '@/services/tokenService';
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_CATALOG,
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      removeToken()
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default instance;