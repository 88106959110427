<template>
    <NavBarUser />
    <div class="container">
        <div class="row mt-2">
            <div class="col-12 mb-2 d-flex">
                <h5 class="my-auto">Usuários</h5>
                <a class="nav-link text-primary text-decoration-none" href="#" @click="openNewUser">
                    <i class="fa fa-plus-square"></i>&nbsp; <strong>Novo</strong></a>
            </div>
            <div class="col-12">
                <div class="col-12 p-3" v-if="!users.length">
                    <h3 class="text-primary text-center">Sem Informações para mostrar</h3>
                </div>

                <div class="col-12" v-else v-for="user in users" :key="user.id" :id="'user-' + user.id">
                    <div class="card mb-0 shadow-sm mb-1">
                        <div class="card-body p-2 rounded border-4 border-start border-info">
                            <div class="row align-items-center gx-1">
                                <div class="col-1 d-flex justify-content-between">                                   
                                    <a v-if="user.id !== getUserId()" class="text-danger d-none d-md-inline" href="#!" @click="openDeleteModal(user)">
                                        <i class="fa fa-trash m-0"></i><strong>Excluir</strong>
                                    </a>

                                    <a v-if="user.id !== getUserId()" class="text-danger d-inline d-md-none" href="#!" @click="openDeleteModal(user)">
                                        <i class="fa fa-trash m-0"></i>
                                    </a>
                                </div>
                                <div class="col-auto">
                                    <p class="m-0"><strong>Id:</strong> {{ user.id }}</p>
                                </div>
                                <div class="col-auto">
                                    <p class="m-0"><strong>Usuário:</strong> {{ user.username }}</p>
                                </div>
                                <div class="col-auto">
                                    <p class="m-0"><strong>Nome:</strong> {{ user.name }}</p>
                                </div>

                                <div class="col-auto">
                                    <p class="m-0"><strong>E-mail:</strong> {{ user.email }}</p>
                                </div>
                                <div class="col-auto">
                                    <p class="m-0"><strong>Perfil:</strong> {{ parseRole(user.role) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div v-if="newUserModalVisible" class="modal fade show d-block" tabindex="-1" aria-labelledby="newUserModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="newUserModalLabel">Novo Usuário</h5>
                    <button type="button" class="btn-close" @click="closeNewUser" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="createNewUser(newUser)">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="name">Nome</label>
                                <input type="text" class="form-control form-control-sm" id="name" v-model="newUser.name"
                                    required>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="username">Usuário</label>
                                <input type="username" class="form-control form-control-sm" id="username"
                                    v-model="newUser.username" required>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="email">E-mail</label>
                                <input type="mail" class="form-control form-control-sm" id="email"
                                    v-model="newUser.email" required>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="username">Perfil</label>
                                <select class="form-control form-control-sm" v-model="newUser.role" required>
                                    <option value="SALES" >{{parseRole('SALES')}}</option>
                                    <option value="ADMIN" >{{parseRole('ADMIN')}}</option>
                                </select>
                            </div>
                        </div>
                        <p v-if="newUser.username.trim() !== ''"><b class="text-primary">Atenção!</b> A nova senha do
                            usuário é: [ <b class="text-primary">{{ newUser.username }}</b> ], e poderá ser trocada
                            posteriormente.</p>
                        <button type="submit" class="btn btn-primary">Salvar</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div v-if="deleteUserModalVisible"  class="modal fade show d-block" tabindex="-1" aria-labelledby="deleteUserModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="deleteUserModalLabel">Exclusão</h5>
                    <button type="button" class="btn-close" aria-label="Close" @click="closeDeleteModal()"></button>
                </div>
                <div class="modal-body">
                    <p class="lead text-justify mb-0">Deseja realmente excluir o usuário {{ userDeleteName }}?</p>
                    <p class="lead text-justify"><strong class="text-danger">Esta ação não poderá ser desfeita!</strong></p>
                    <button type="submit" class="btn btn-primary" @click="deleteUser(this.userDeleteId)">Excluir</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavBarUser from '@/components/NavBarUser.vue';
import PasswordService from '@/services/passwordService';
import { getDecodedToken, renewToken } from '@/services/tokenService';
import UserService from '@/services/userService';

export default {
    name: 'UsersPage',
    components: {
        NavBarUser
    },
    data() {
        return {
            users: [],
            newUser: {},
            userDeleteName: '',
            newUserModalVisible: false,
            updateUserModalVisible: false,
            deleteUserModalVisible: false
        }
    },
    async mounted() {
        this.users = await UserService.getAll(this.$root.showNotification);
    },
    methods: {
        async changePassword() {
            await PasswordService.changePassword(this.change, this.$root.showNotification);
        },

        async saveUser() {
            try {
                await UserService.update(this.user, this.$root.showNotification);
            } finally {
                renewToken();
                this.$router.push('/profile');
            }
        },

        async createNewUser(user) {
            try {
                await UserService.create(user, this.$root.showNotification);
            } finally {            
                this.users = await UserService.getAll(this.$root.showNotification);
                this.closeNewUser();       
            }
        },

        async deleteUser(id) {
            try {   
                await UserService.delete(id, this.$root.showNotification);
            } finally {
                this.users = await UserService.getAll(this.$root.showNotification);
                this.closeDeleteModal();
            }
        },  

        openNewUser() {
            this.newUser = {
                name: '',
                username: '',
                email: '',
                role: 'SALES'
            };

            this.newUserModalVisible = true;
        },

        closeNewUser() {
            this.newUserModalVisible = false;
        },

        openEditUser() {
            this.newUser = {
                name: '',
                username: '',
                email: '',
                role: 'SALES'
            };

            this.updateUserModalVisible = true;
        },

        closeEditUser() {
            this.updateUserModalVisible = false;
        },    
        
        openDeleteModal(user) {
            this.userDeleteName = user.username;
            this.userDeleteId = user.id;
            this.deleteUserModalVisible = true;
        },

        closeDeleteModal() {
            this.userDeleteName = '';
            this.userDeleteId = '';
            this.deleteUserModalVisible = false;
        },

        getUserId() {
            return getDecodedToken().id ?? 0;
        },

        parseRole(text) {
            return UserService.parseRoleText(text);
        }
    }

}
</script>