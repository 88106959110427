<template>
    <div class="container">
        <div class="row py-5">
            <div class="mx-auto my-auto col-lg-6 col-8 d-flex justify-content-center g-0">
                <h4 class="my-auto d-none d-md-block">Catálogo Web |</h4>
                <img class="d-block my-auto mx-auto" src="logo.png">
            </div>
            <div v-if="!isRecovery" class="col-10 col-lg-4 p-3 mx-auto mt-1 bg-white border rounded shadow-sm">
                <h3 class="mb-2">Acesso</h3>
                <form @submit.prevent="doLogin(login)">
                    <div class="form-row mb-3">
                        <div class="form-floating col-12">
                            <input type="username" class="form-control form-control-sm" id="username"
                                name="username" placeholder="" v-model="login.username" required>
                            <label for="username">Usuário</label>
                        </div>
                    </div>
                    <div class="form-row mb-3">
                        <div class="form-floating col-12">
                            <input type="password" class="form-control form-control-sm" id="password"
                                name="password" placeholder="" v-model="login.password" required>
                            <label for="password">Senha</label>
                        </div>
                        <a v-if="false" href="#" class="mt-1 mx-1 text-primary"
                           @click="activeRecovery">Recuperar senha</a>
                    </div>
                    <button class="btn btn-outline-primary" type="submit">Entrar</button>&nbsp;
                    <a class="btn btn-outline-info" href="/">Voltar</a>
                </form>
            </div>
            <div v-else class="col-10 col-lg-4 p-3 mx-auto mt-1 bg-white border rounded shadow-sm">
                <h3 class="mb-2">Recuperar Senha</h3>
                <p class="text-justify">Caso o e-mail seja válido na plataforma 
                   você receberá as instruções para redefinir sua senha.</p>
                <form @submit.prevent="login" method="POST">
                    <div class="form-row mb-3">
                        <div class="form-floating col-12">
                            <input type="text" class="form-control form-control-sm" id="username"
                                name="username" placeholder="" v-model="recovery.username" required>
                            <label for="username">Usuário</label>
                        </div>
                    </div>
                    <button class="btn btn-outline-primary" type="submit">Solicitar</button>&nbsp;
                    <a class="btn btn-outline-info" href="#"
                       @click="activeLogin">Voltar</a>
                </form>
            </div>            
        </div>
    </div>
</template>

<script>
import LoginService from '@/services/loginService';

export default {
    name: 'LoginPage',
    data() {
        return {
            login: {
                username: null,
                password: null
            },
            recovery: {
                email: null
            },
            isRecovery: false
        }
    },
    created() {
        this.isVisibleMenus = false;
        LoginService.logout();
    },
    methods: {
        activeRecovery() {
            this.isRecovery = true;
        },
        activeLogin() {
            this.isRecovery = false;
        },
        doLogin(data = {}) {
            LoginService.login(data, this.$root.showNotification);
        }
    }
}
</script>

<style scoped></style>