<template>
  <div class="py-1">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
        </div>
      </div>
      <div class="row">
        <div v-if="products.length === 0" class="col-md-12 py-5 my-5">
          <h3 class="text-center">NÃO EXISTEM PRODUTOS NESTA CATEGORIA/DESCRIÇÃO</h3>
        </div>        
        <ProductCatalog v-else v-for="product in products" :key="product.id" :product="product" />
      </div>
    </div>
  </div> 
</template>

<script>
import ProductCatalog from './ProductCatalog.vue';

export default {
  name: 'ProductsList',
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  components: {
    ProductCatalog
  }
};
</script>

<style scoped>
</style>