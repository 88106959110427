import axios from "@/plugins/axios";
import BaseService from "./baseService";
import router from "@/router";
import { getDecodedToken, removeToken, setToken } from "./tokenService";

class LoginService {
  static async login(data = {}, notify) {
    try {
      if (!data || !data.username || !data.password) {
        notify(
          "warning",
          "Informe usuário/senha para realizar o acesso!",
          5000
        );
        return;
      }

      const response = await axios.post("/auth/login", {
        username: data.username,
        password: data.password,
      });

      const token = response.data.data.token;
      setToken(token);

      const decodedToken = getDecodedToken();

      if (decodedToken.role in ['VISITOR']) {
        //removeToken();
        this.$root.showNotification(
          "warning",
          "Perfil não permitido nesta área"
        );
      }

      router.push("/");
    } catch (error) {
      notify(
        "warning",
        BaseService.handleError(error, `Erro de autenticação`),
        5000
      );
    }
  }

  static logout() {
    removeToken();
  }

  static newSample() {
    return {
      tog: 0,
    };
  }
}

export default LoginService;
