<template>
    <NavBarUser />
    <div class="container">
        <div class="row mt-2">
            <div class="col-12">
                <br>
                <div class="card mb-4 shadow-sm">
                    <div class="card-header p-1">
                        <h4>Dados do Usuário</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="saveUser">
                            <div class="form-row">
                                <div class="form-group col-md-8">
                                    <label for="name">Nome</label>
                                    <input type="text" class="form-control" id="name" v-model="user.name" required>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="username">Usuário</label>
                                    <input type="text" class="form-control" id="username" v-model="user.username" disabled required>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="email">E-mail</label>
                                    <input type="email" class="form-control" id="email" v-model="user.email">
                                </div>
                            </div>
                            <button type="button" class="btn btn-outline-secondary m-1" data-bs-toggle="modal"
                                data-bs-target="#passwordModal">
                                Trocar Senha
                            </button>
                            <button type="submit" class="btn btn-outline-primary m-1">Salvar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="passwordModal" tabindex="-1" aria-labelledby="passwordModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="passwordModalLabel">Trocar Senha</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="changePassword">
                        <div class="form-group">
                            <label for="newPassword">Nova Senha</label>
                            <input type="password" class="form-control" id="newPassword" v-model="change.newPass" required>
                        </div>
                        <div class="form-group">
                            <label for="confirmPassword">Confirmar Nova Senha</label>
                            <input type="password" class="form-control" id="confirmPassword" v-model="change.confirmPass"
                                required>
                        </div>
                        <button type="submit" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#passwordModal">Alterar Senha</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavBarUser from '@/components/NavBarUser.vue';
import PasswordService from '@/services/passwordService';
import { getDecodedToken, renewToken } from '@/services/tokenService';
import UserService from '@/services/userService';

export default {
    name: 'ProfilePage',
    components: {
        NavBarUser
    },
    data() {
        return {
            user: {
                id: '',
                username: '',
                name: '',
                email: '',
            },
            change: {
                id: '',
                username: '',
                newPass: '',
                confirmPass: ''
            }
        }
    },
    mounted() {
        const user = getDecodedToken();
        this.user = { ...user };
        this.change.id = this.user.id;
        this.change.username = this.user.username;
    },
    methods: {
        async changePassword() {
           await PasswordService.changePassword(this.change, this.$root.showNotification);
        },

        async saveUser() {
            try  {
                await UserService.update(this.user, this.$root.showNotification);
            } finally {
                renewToken();
                this.$router.push('/profile');
            }
        },

        parseRole(text) {
            return UserService.parseRoleText(text);
        }
    }

}
</script>