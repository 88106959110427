class BaseService {
    static handleError(error, message = "Erro desconhecido") {
        // console.error(message, error);
        if (error.response && error.response.data && error.response.data.message) {
          return error.response.data.message+ '  '+error.response.data.data;
        } else {
          return message;
        }
      }

    static formatDate(dateString) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }
}

export default BaseService;