<template>
  <div id="productsPage">
    <NavBar />
    <NavMenu />
    <h1>{{ categoryName }}</h1>
    <ProductsList :products="products" />
    <div v-if="totalPages > 1">
      <PagComp :currentPage="currentPage" :totalPages="totalPages" :hasMore="hasMore" @page-changed="onPageChanged" />
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import NavMenu from '@/components/NavMenu.vue';
import ProductsList from '@/components/ProductsList.vue';
import PagComp from '@/components/PagComp.vue';

export default {
  name: 'ProductsPage',
  components: {
    NavBar,
    NavMenu,
    ProductsList,
    PagComp
  },
  data() {
    return {
      products: [],
      currentPage: 1,
      limit: 12,
      totalPages: 0,
      hasMore: true,
      error: null,
      loading: false,
    };
  },
  methods: {
    async fetchProducts(page = 1) {
      if (this.loading) return;

      this.loading = true;
      this.currentPage = Number(page);

      const query = this.$route.query.qr ?? '';
      const categoryId = this.$route.params.id ?? '';
      const offset = (this.currentPage - 1) * this.limit;

      let uri = '';

      if (query) {
        uri = `/products/query?l=${this.limit}&o=${offset}&qr=${query}`;
      } else if (categoryId) {
        uri = `/products/tag/${categoryId}?l=${this.limit}&o=${offset}`;
      } else {
        uri = `/products?l=${this.limit}&o=${offset}`;
      }

      try {
        const response = await this.$axios.get(uri);
        this.products = response.data.items ?? [];
        const totalItems = response.data.total ?? 0;
        this.totalPages = Math.ceil(totalItems / this.limit);
        this.hasMore = this.currentPage < this.totalPages;

      } catch (error) {
        console.error('Erro ao buscar produtos:', error);
        this.error = 'Erro ao buscar produtos';
      } finally {
        this.loading = false;
      }
    },

    onPageChanged(page) {
      if (page !== this.currentPage) {
        this.$router.push({ path: this.$route.path, query: { ...this.$route.query, page } });
      }
    }
  },
  watch: {
    '$route.query.qr': {
      handler() {
        this.currentPage = 1;
        this.fetchProducts(1);
      },
      immediate: true, // Executa ao iniciar o componente
    },
    '$route.params.id': {
      handler() {
        this.currentPage = 1;
        this.fetchProducts(1);
      },
      immediate: true, // Executa ao iniciar o componente
    },
    '$route.query.page': {
      handler(newPage) {
        const page = newPage || 1;
        if (page !== this.currentPage) {
          this.fetchProducts(page);
        }
      },
      immediate: true, // Executa ao iniciar o componente
    }
  },
  created() {
    const page = this.$route.query.page || 1;
    this.fetchProducts(page);
  },
}
</script>