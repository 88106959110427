<template>
  <div id="app">
    <router-view></router-view>
    <NotificationBox ref="notificationBox" />

    <footer class="p-2 stick-bottom text-muted">
      <div class="container">
        <p class="float-right">
          <a href="#" class="text-primary">Voltar para o topo</a>
        </p>
        <p>Carreiro Móveis © 2024. Todos os Direitos Reservados</p>
      </div>
    </footer>
  </div>
</template>

<script>
import NotificationBox from './components/NotificationBox.vue';

export default {
  name: 'App',
  components: {
    NotificationBox
  },
  methods: {
    showNotification(type, message, duration = 3000) {
      this.$refs.notificationBox.triggerNotification(type, message, duration);
    },
  }
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgba(240, 239, 239, 0.315)
}

router-view {
  flex: 1; /* Faz o conteúdo do router ocupar o espaço disponível */
}

footer {
  text-align: center;
}
</style>
