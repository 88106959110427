<template>
  <div id="productPage">
    <NavBar />
    <NavMenu />
    <ProductView :product="product"/>
  </div>
</template>

<script>  
import NavBar from '@/components/NavBar.vue';
import ProductView from '../components/ProductView.vue';
import NavMenu from '@/components/NavMenu.vue';

export default {
name: 'ProductPage',
components: {
  NavBar,
  NavMenu,
  ProductView
},
inject: ['isVisibleMenus'],
data() {
  return {
    categoryName: '',
    product: null,
    products: [],
    error: null,
    loading: false,
  };
},
methods: {
  async fetchProductData() {
    this.loading = true;
    const productId = this.$route.params.id;
    try {
      const productResponse = await this.$axios.get(`/products/${productId}`);
      this.product = productResponse.data.item;
    } catch (err) {
      this.error = err.message;
      if (err.response) {
        this.error = `Error: ${err.response.status} - ${err.response.data.message}`;
      } else if (err.request) {
        this.error = 'Error: No response received from server.';
      } else {
        this.error = `Error: ${err.message}`;
      }
    } finally {
      this.loading = false;
    }
  }
},
watch: {
  '$route.params.id': 'fetchProductData'
},
created() {
  this.isVisibleMenus = true;
  this.fetchProductData();
}
};
</script>

<style>
</style>
