import { jwtDecode } from "jwt-decode";
import router from "../router";
import axios from "@/plugins/axios";

let logoutTimer;

export function getToken() {
  const token = localStorage.getItem("token");
  return token;
}

export function setToken(token) {
  localStorage.setItem("token", token);
  startTokenTimer(token);
}

export function removeToken() {
  localStorage.removeItem("token");
  clearTimeout(logoutTimer);
}

export function isLogged() {
  const token = getToken();
  return !isTokenExpired(token);
}

export function isTokenExpired(token) {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    return true;
  }
}

export async function renewToken() {
  try {
    const token = getToken();
    if (!token || isTokenExpired(token)) {
      //throw new Error("Token inválido ou expirado");
      return;
    }

    const response = await axios.post("/auth/renew");

    if (response?.data?.status === 200 && response?.data?.data?.token) {
      setToken(response.data.data.token);
      return response.data.data.token;
    } else {
      removeToken();
      throw new Error("Falha ao renovar o token");
    }
  } catch (error) {
    removeToken();
    throw error;
  }
}

function startTokenTimer(token) {
  const decoded = jwtDecode(token);
  const expiresIn = decoded.exp * 1000 - Date.now();

  if (expiresIn <= 0) {
    console.log("Token expirado.");
    removeToken();
    router.push("/login");
  } else {
    const timeout = Math.max(0, expiresIn - 60000);

    logoutTimer = setTimeout(async () => {
      try {
        await renewToken();
      } catch (error) {
        removeToken();
        router.push("/login");
      }
    }, timeout);
  }
}

export function getDecodedToken() {
  const token = getToken();
  if (token) {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Erro ao decodificar o token:", error);
      return null;
    }
  }
  return null;
}
