<template>
  <nav aria-label="Page navigation">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a class="page-link" href="#" @click.prevent="changePage(1)">«</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">‹</a>
      </li>

      <li class="page-item" v-if="startPage > 1">
        <a class="page-link" href="#" @click.prevent="changePage(startPage - 1)">...</a>
      </li>

      <li
        class="page-item"
        v-for="page in displayedPages"
        :key="page"
        :class="{ active: page === currentPage }"
      >
        <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
      </li>

      <li class="page-item" v-if="endPage < totalPages">
        <a class="page-link" href="#" @click.prevent="changePage(endPage + 1)">...</a>
      </li>

      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">›</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="#" @click.prevent="changePage(totalPages)">»</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'PagComp',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    hasMore: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    displayedPages() {
      const pagesToShow = 5;
      let startPage = Math.max(this.currentPage - Math.floor(pagesToShow / 2), 1);
      let endPage = startPage + pagesToShow - 1;

      if (endPage > this.totalPages) {
        endPage = this.totalPages;
        startPage = Math.max(endPage - pagesToShow + 1, 1);
      }

      return Array.from({ length: endPage - startPage + 1 }, (v, k) => startPage + k);
    },
    startPage() {
      return this.displayedPages[0];
    },
    endPage() {
      return this.displayedPages[this.displayedPages.length - 1];
    }
  },
  methods: {
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.$emit('page-changed', page);
      }
    }
  }
};
</script>

<style scoped>
</style>
