<template>
  <div class="container-fluid">
    <div class="container p-1">
      <div v-if="product" class="row g-2">
        
        <div class="col-md-1 col-sm-12 p-1 d-none d-md-block">
          <div class="thumbnails-md">
            <img v-for="(image, index) in carouselImages" :key="index" :src="image" class="thumbnail-img"
              :class="{ active: index === activeIndex }" @click="setActiveImage(index)" alt="Thumbnail" />
          </div>
        </div>

        <div class="col-md-8 col-sm-12">
          <div id="productCarousel" class="carousel slide" data-bs-ride="false">
            <ol class="carousel-indicators">
              <li v-for="(image, index) in carouselImages" :key="index" :data-bs-target="'#productCarousel'"
                :data-bs-slide-to="index" :class="{ active: index === activeIndex }"></li>
            </ol>
            <div class="carousel-inner">
              <div v-for="(image, index) in carouselImages" :key="index"
                :class="['carousel-item', { active: index === activeIndex }]">
                <img class="d-block w-100 mx-auto carousel-img" :src="image" alt="Product Image"
                  @click="openZoomModal(image)" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 p-1 d-block d-md-none justify-content-center">
          <div class="thumbnails-sm">
            <img v-for="(image, index) in carouselImages" :key="index" :src="image" class="thumbnail-img"
              :class="{ active: index === activeIndex }" @click="setActiveImage(index)" alt="Thumbnail" />
          </div>
        </div>

        <div class="col-md-3 col-sm-12 py-2 bg-white rounded shadow-sm">
          <p class="card-text mt-2 mb-0">COD.:&nbsp;<b>{{ product.id_erp }}</b></p>
          <hr class="m-0" />
          <p class="card-text mb-0 text-justify"><b>{{ product.description }}</b></p>
          <p class="card-text mt-2 mb-0" v-if="getLogged()"><b>R$ {{ product.price }}</b></p>
          <small class="text-muted" v-if="getLogged() && 1==0">em 12x de R$ {{ installment }}</small>
          <p class="card-text mt-2 mb-0"><b>{{ stockStatus }}</b></p>
          <div class="text-center mb-1 mt-1">
            <div class="btn-group">
              <a class="btn btn-primary" v-bind:href="dynamicHref" target="_blank">
                <i class="fa-brands fa-whatsapp"></i>&nbsp;Entre em contato</a>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="text-center py-5 m-5">
        <h2>PRODUTO NÃO ENCONTRADO</h2>
      </div>
    </div>
  </div>

  <!-- Modal de Zoom -->
  <div v-if="isZoomed" class="modal-overlay">
    <div class="modal-content">
      <button class="close-button" @click="closeZoom">&times;</button>
    
      <a class="carousel-control-prev" role="button" @click="prevImage">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </a>

      <img :src="zoomedImage" alt="Zoomed Product Image" class="zoomed-image" />

      <a class="carousel-control-next" role="button" @click="nextImage">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </a>

      <div class="modal-thumbnails">
          <img v-for="(image, index) in carouselImages" :key="index" :src="image" class="thumbnail-img"
              :class="{ active: zoomedIndex === index }" @click="updateZoomImage(index)" alt="Thumbnail" />
      </div>
    </div>
  </div>  

</template>

<script>
import { linkWhatsApp } from '@/services/contactService';
import { isLogged } from '@/services/tokenService';

export default {
  name: "ProductView",
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeIndex: 0, 
      isZoomed: false,
      zoomedImage: null,
    };
  },
  computed: {
    installment() {
      const price = parseFloat(this.product?.price || 0);
      return (price / 12).toFixed(2);
    },
    stockStatus() {
      return this.product?.stock_quantity > 0
        ? "Estoque Disponível: " + Math.round(this.product?.stock_quantity)
        : "Indisponível";
    },
    carouselImages() {
      if (this.product?.images && this.product.images.length > 0) {
        return this.product.images.map((image) => image.link);
      }
      return ["/default-image1.webp"];
    },
    dynamicHref() {
      return linkWhatsApp(this.product.id_erp, this.product.short_description);
    }
  },
  methods: {
    getLogged() {
      return isLogged();
    },

    setActiveImage(index) {
      this.activeIndex = index;
    },

    prevSlide() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        this.activeIndex = this.carouselImages.length - 1;
      }
    },

    nextSlide() {
      if (this.activeIndex < this.carouselImages.length - 1) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0;
      }
    },

    openZoomModal(image) {
      this.zoomedImage = image;
      this.isZoomed = true;
      document.body.style.overflow = "hidden"; // Impede o scroll da página ao abrir o modal
    },
    closeZoom() {
      this.isZoomed = false;
      this.zoomedImage = null;
      document.body.style.overflow = ""; // Permite o scroll da página ao fechar o modal
    },
    updateZoomImage(index) {
      this.zoomedImage = this.carouselImages[index];
      this.zoomedIndex = index;
    },
    prevImage() {
      this.zoomedIndex = (this.zoomedIndex > 0) ? this.zoomedIndex - 1 : this.carouselImages.length - 1;
      this.zoomedImage = this.carouselImages[this.zoomedIndex];
    },
    nextImage() {
      this.zoomedIndex = (this.zoomedIndex < this.carouselImages.length - 1) ? this.zoomedIndex + 1 : 0;
      this.zoomedImage = this.carouselImages[this.zoomedIndex];
    }    
  },
};
</script>

<style scoped>
.cover {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

.cover-content {
  text-align: center;
}

.carousel-img {
  object-fit: contain;
  height: 405px;
  cursor: pointer;
  min-height: 350px;
}

.thumbnails-md {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.thumbnails-sm {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.thumbnail-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-bottom: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.thumbnail-img.active {
  border-color: #e64332;
}

.thumbnail-img:hover {
  border-color: #e64332;
}

/* Overlay do Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Conteúdo do Modal */
.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px;
  text-align: center;
}

/* Botão de Fechar */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10000;
}

/* Imagem com Zoom */
.zoomed-image {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
}

/* Ajuste de visibilidade da página ao abrir o modal */
.container-fluid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Ajustes adicionais para o layout */
.carousel-indicators li {
  background-color: #e64432e3;
  border: 2px solid white;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 2px;
}

.carousel-indicators .active {
  background-color: #e64432;
  border-color: rgba(0, 0, 0, 0.096);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15%;
  padding: 10px;
  width: 40px;
  height: 40px;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  background-color: #e64432e3;
}
</style>