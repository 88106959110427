<template>
    <transition name="fade">
        <div v-if="visible" :class="['notification', notificationType]" class="notification-container">
            <div class="icon-container">
                <i :class="icon"></i>
            </div>
            <div class="message">{{ notificationMessage }}</div>
            <button @click="hideNotification" class="close-btn">&times;</button>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'success',
        },
        message: {
            type: String,
            required: true,
        },
        duration: {
            type: Number,
            default: 5000,
        },
    },
    data() {
        return {
            visible: false,
            notificationType: this.type,
            notificationMessage: this.message,
        };
    },
    watch: {
        type(newType) {
            this.notificationType = newType;
        },
        message(newMessage) {
            this.notificationMessage = newMessage;
        },
    },
    computed: {
        icon() {
            switch (this.notificationType) {
                case 'success':
                    return 'fa fa-check-circle';
                case 'warning':
                    return 'fa fa-exclamation-triangle';
                case 'danger':
                    return 'fa fa-times-circle';
                default:
                    return '';
            }
        },
    },
    methods: {
        hideNotification() {
            this.visible = false;
        },
        triggerNotification(type, message, duration = 3000) {
            this.notificationType = type;
            this.notificationMessage = message;
            this.visible = true;

            setTimeout(() => {
                this.hideNotification();
            }, duration);
        },
    },
};
</script>

<style scoped>
.notification-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 8px;
    color: white;
    z-index: 9999;
}

.notification.success {
    background-color: #28a745;
}

.notification.warning {
    background-color: #fcc214;
}

.notification.danger {
    background-color: #dc3545;
}

.icon-container {
    margin-right: 10px;
    font-size: 24px;
}

.message {
    flex: 1;
    font-size: 16px;
}

.close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>