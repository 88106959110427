<template>
  <nav class="navbar navbar-expand-sm navbar-light bg-primary py-0">
    <div class="container d-flex justify-content-center">
      <div class="row w-100 d-flex justify-content-left">
        <div class="col" v-for="section in sections" :key="section.section_id">
          
          <div class="btn-group">
            <router-link class="btn btn-sm btn-primary" style="width: 122px;" :to="`/c/${section.section_id}`" @click="selectSection(section)">
              <b>{{ section.section_name }}</b>
            </router-link>
            <a class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="visually-hidden"></span>
            </a>
            <ul class="dropdown-menu">
              <li v-for="item in getCombinedItems(section.section_id)" :key="item.id">
                <router-link class="dropdown-item" :to="`/c/${item.id}`" @click="selectItem(item)">
                  {{ item.name }}
                </router-link>
              </li>
              <!--<li><hr class="dropdown-divider"></li>-->              
            </ul>
          </div>

        </div>
      </div>
    </div>
  </nav>

  <nav class="container" v-if="selectedSection || selectedGroup || selectedSubgroup">
    <ol class="breadcrumb mb-0 pb-0" style="background-color: #F7F7F7;">
      <li class="breadcrumb-item mb-0 pb-0" v-if="selectedSection">
        <router-link :to="`/c/${selectedSection.section_id}`" @click="resetToSection" class="mb-0 pb-0">
          {{ selectedSection.section_name }}
        </router-link>
      </li>
      <li class="breadcrumb-item mb-0 pb-0" v-if="selectedGroup">
        <router-link :to="`/c/${selectedGroup.id}`" @click="resetToGroup" class="mb-0 pb-0">
          {{ selectedGroup.name }}
        </router-link>
      </li>
      <li class="breadcrumb-item mb-0 pb-0" v-if="selectedSubgroup">
        {{ selectedSubgroup.name }}
      </li>
    </ol>
  </nav>  
</template>

<script>
export default {
  data() {
    return {
      sections: [],
      groups: [],
      subgroups: [],
      selectedSection: null,
      selectedGroup: null,
      selectedSubgroup: null,
    };
  },
  methods: {
    async fetchMenuItems() {
      try {
        const response = await this.$axios.get('/groups/tree');
        const items = response.data.items;

        // Organizar seções, grupos e subgrupos
        this.sections = items.reduce((acc, item) => {
          if (!acc.some(sec => sec.section_id === item.section_id)) {
            acc.push({ section_id: item.section_id, section_name: item.section_name });
          }
          return acc;
        }, []);

        this.groups = items.reduce((acc, item) => {
          if (!acc.some(grp => grp.group_id === item.group_id)) {
            acc.push({ id: item.group_id, name: item.group_name, section_id: item.section_id, type: 'group' });
          }
          return acc;
        }, []);

        this.subgroups = items.reduce((acc, item) => {
          if (!acc.some(subgrp => subgrp.subgroup_id === item.subgroup_id)) {
            acc.push({ id: item.subgroup_id, name: item.subgroup_name, section_id: item.section_id, type: 'subgroup' });
          }
          return acc;
        }, []);
      } catch (err) {
        console.error(err);
      }
    },

    // Método que combina grupos e subgrupos associados a uma seção e remove duplicados
    getCombinedItems(section_id) {
      const groupItems = this.groups.filter(group => group.section_id === section_id);
      const subgroupItems = this.subgroups.filter(subgroup => subgroup.section_id === section_id);

      // Combina todos os itens e elimina duplicados por nome
      const allItems = [...groupItems, ...subgroupItems];

      const uniqueItems = allItems.reduce((acc, current) => {
        if (!acc.some(item => item.name === current.name)) {
          acc.push(current);
        }
        return acc;
      }, []);

      return uniqueItems;
    },

    selectSection(section) {
      this.selectedSection = section;
      this.selectedGroup = null;
      this.selectedSubgroup = null;
    },
    selectItem(item) {
      if (item.type === 'group') {
        this.selectedGroup = item;
        this.selectedSubgroup = null;
      } else if (item.type === 'subgroup') {
        console.log(item);
        this.selectedSubgroup = item;
      }
    },
    resetToSection() {
      this.selectedGroup = null;
      this.selectedSubgroup = null;
    },
    resetToGroup() {
      this.selectedSubgroup = null;
    }
  },
  created() {
    this.fetchMenuItems();
  }
};
</script>
