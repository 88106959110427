import axios from "@/plugins/axios";
import BaseService from "./baseService";

class UserService {
  static async create(data = {}, notify) {
    try {
      if (!data || !data.name || !data.username || !data.email) {
        notify("warning", "Dados incompletos!", 5000);
        return;
      }

      await axios.post(`/users`, {
        name: data.name,
        email: data.email,
        username: data.username,
        password: data.username,
        role: data.role
      })
      .then(() => {
        notify('success', `Usuário ${data.username} criado com sucesso!`, 5000);
        return;
      });
    } catch (error) {
      notify("warning",
        BaseService.handleError(error, `Falha ao criar usuário ${data.username}, entre em contato com o suporte`), 5000);
      return;
    }
  }

  static async update(data = {}, notify) {
    try {
      if (!data || !data.id || !data.name || !data.username || !data.email) {
        notify("warning", "Dados incompletos!", 5000);
        return;
      }

      await axios.put(`/users/${data.id}`, {
        name: data.name,
        email: data.email
      })
      .then(() => {
        notify('success', `Usuário ${data.username} alterado com sucesso!`, 5000);
        return;
      });
    } catch (error) {
      notify("warning",
        BaseService.handleError(error, `Falha ao alterar usuário ${data.username}, entre em contato com o suporte`), 5000);
      return;
    }
  }  
  
  static async delete(id = 0, notify) {
    try {
      if (id == 0) {
        notify("warning", "Informe o usuário!", 5000);
        return;
      }

      await axios.delete(`/users/${id}`)
      .then(() => {
        notify('success', `Usuário ${id} excluído com sucesso!`, 5000);
        return;
      });
    } catch (error) {
      notify("warning",
        BaseService.handleError(error, `Falha ao excluir usuário ${id}, entre em contato com o suporte`), 5000);
      return;
    }
  }

  static async getAll(notify) {
    try {
      const users = await axios.get('/users?l=1000&o=0');
      return users.data.data ?? [];
    } catch (error) {
      notify("warning",
        BaseService.handleError(error, `Falha ao alterar buscar usuários, entre em contato com o suporte`), 5000);
      return;
    }
  }

  static parseRoleText(text) {
    switch (text) {
      case "ADMIN":
        return "Administrador";
      case "USER":
        return "Usuário";
      case "SALES":
        return "Vendedor";
      case "VISITOR":
        return "Visitante";
      default:
        return text;
    }
  }

}

export default UserService;
